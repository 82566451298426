import * as React from "react"
import { Accordion } from "react-bootstrap"
import { useState } from "react"

import AddAccounting from "./addAccounting"

const Accounting = () => {
  const [showAddAccounting, setShowAddAccounting] = useState(false)

  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header>
        <i className="bi bi-currency-dollar me-2" /> Accounting Settings
      </Accordion.Header>
      <Accordion.Body>
        <div className="row g-0 block-content align-items-center border-bottom">
          <div className="col-6">
            <h5 className="block-title">Calculate Internal Cost for SKUs &amp; Invoices:</h5>
          </div>
          <div className="col-6 text-end pr-15">
            <div className="toggleSwitch">
              <input type="checkbox" value="1" name="calculateInternalCost" id="calculateInternalCost" />
              <label />
            </div>
          </div>
        </div>
        <div className="row g-0 block-content align-items-center border-bottom">
          <div className="col-md-12 p-3">
            <div className="row mb-2 align-items-center">
              <div className="col-md-12">
                <h4 className="mb-0">Billing &amp; Late Payment Policies:</h4>
                <p>We will attempt auto-billing if available for that user.</p>
              </div>
            </div>
            <AddAccounting
              showAddAccounting={showAddAccounting}
              setShowAddAccounting={setShowAddAccounting}
            />
            <div className="row mb-2 align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 text-end">
                <button
                  className="btn btn-primary btnAddNewAgreementsLease"
                  onClick={() => setShowAddAccounting(!showAddAccounting)}
                >
                  <i className="bi bi-plus-lg" /> Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table id="tblAgreementsLease" className="table table-striped dataTable no-footer" width="100%">
                <thead>
                  <tr>
                    <th width="90%">Label / Status</th>
                    <th width="10%" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Current</td>
                    <td className="text-end tableColAction d-none d-md-table-cell">
                      <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" title="Edit">
                        <i className="bi bi-pencil-fill" />
                      </a>{" "}
                      <a href="#" className="btn btn-delete" data-bs-toggle="tooltip" title="Delete">
                        <i className="bi bi-trash-fill" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Grace Period</td>
                    <td className="text-end tableColAction d-none d-md-table-cell">
                      <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" title="Edit">
                        <i className="bi bi-pencil-fill" />
                      </a>{" "}
                      <a href="#" className="btn btn-delete" data-bs-toggle="tooltip" title="Delete">
                        <i className="bi bi-trash-fill" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Late 1 Fee Applied</td>
                    <td className="text-end tableColAction d-none d-md-table-cell">
                      <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" title="Edit">
                        <i className="bi bi-pencil-fill" />
                      </a>{" "}
                      <a href="#" className="btn btn-delete" data-bs-toggle="tooltip" title="Delete">
                        <i className="bi bi-trash-fill" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Accounting
