import * as React from "react"
import { navigate } from "gatsby"
import { Accordion } from "react-bootstrap"

import SettingsLayout from "./settingsLayout"
import IncomingMoney from "./Financial/incomingMoney"
import OutGoingMoney from "./Financial/outgoingMoney"
import Accounting from "./Financial/accounting"

const Financial = ({ params }) => {
  return (
    <SettingsLayout title="Financial" iconClass="currency-dollar me-2" isBusinessPage="1">
      <div className="row g-0 pt-5 financeSettingsTemplate">
        <div className="col-lg-12 incomingMoney generalSettings">
          <Accordion defaultActiveKey="0">
            <IncomingMoney params={params} />
            <OutGoingMoney />
            <Accounting />
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <i className="bi bi-repeat me-2" /> Subscriptions & Agreements{" "}
                <i className="bi bi-box-arrow-up-right ms-2" />
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header
                onClick={() => {
                  return navigate("/settings")
                }}
              >
                <i className="bi bi-gear-fill me-2" /> General Settings{" "}
                <i className="bi bi-box-arrow-up-right ms-2" />
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Financial
