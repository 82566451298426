import * as React from "react"
import { Collapse } from "react-bootstrap"

const AddAccounting = ({ showAddAccounting, setShowAddAccounting }) => {
  return (
    <div className="row">
      <Collapse in={showAddAccounting}>
        <div className="col-lg-6 mb-3 mx-auto addNewAgreementsLeaseContainer">
          <div id="FrmAddNewAgreementsLease" className="form-horizontal">
            <div className="row ml-0 mr-0">
              <div className="col-lg-12 colHeading">
                <strong>
                  <i className="bi bi-plus-lg" /> Add New Agreements / Lease / Rental
                </strong>
              </div>
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 row align-items-center">
                      <div className="col-sm-12">
                        <div className="row align-items-center">
                          <label htmlFor="labelStatus" className="col-sm-4 col-form-label">
                            Label / Status
                          </label>
                          <div className="col-sm-8 account_type">
                            <input type="text" id="labelStatus" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row align-items-center">
                        <label className="col-sm-4" />
                        <div className="col-sm-8">
                          <button
                            type="button"
                            className="btn btn-default btnCancelNewAgreementsLease"
                            onClick={() => setShowAddAccounting(!showAddAccounting)}
                          >
                            <i className="bi bi-x-lg" /> Cancel
                          </button>{" "}
                          <button type="button" className="btn btn-primary btnSaveNewAgreementsLease">
                            <i className="bi bi-check2" /> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default AddAccounting
