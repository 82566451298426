import * as React from "react"
import { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"

import AddIncomingMoney from "./addIncomingMoney"
import AxiosInstance from "../../../services/axiosInstance"
import IncomingMoneySettings from "./incomingMoneySettings"

const IncomingMoney = ({ params }) => {
  const [showAddIncomingForm, setShowAddIncomingForm] = useState(false)
  const [showIncomingSettings, setShowIncomingSettings] = useState(false)
  const [incomingList, setIncomingList] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [reloadData, setReloadData] = useState(false)

  const urlParams = new URLSearchParams(params).toString()
  if (params.code && !isLoading) {
    setIsLoading(true)
    AxiosInstance.get(`/setting-secure/stripe-redirect-url?${urlParams}`)
      .then(response => {
        alert(response.data.data.message)
        if (!response.data.data.error) {
          getIncomingList()
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          alert(error.response.data.data?.message)
        }
      })
  }

  const getIncomingList = () => {
    AxiosInstance.get(`/setting-secure/list/incoming`).then(response => {
      setIncomingList(response.data.items)
    })
  }

  const handleDelete = id => {
    AxiosInstance.delete(`/setting-secure/${id}`).then(response => {
      setReloadData(true)
    })
  }

  useEffect(() => {
    getIncomingList()
  }, [])

  useEffect(() => {
    if (reloadData === true) {
      getIncomingList()
      setReloadData(false)
    }
  })

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <i className="bi bi-box-arrow-in-right me-2" /> Incoming Money / Getting Paid
      </Accordion.Header>
      <Accordion.Body className={"p-3"}>
        <div className="row g-0">
          <div className="col-lg-12">
            <AddIncomingMoney
              showAddIncomingForm={showAddIncomingForm}
              setShowAddIncomingForm={setShowAddIncomingForm}
              getIncomingList={getIncomingList}
            />
            <IncomingMoneySettings
              showIncomingSettings={showIncomingSettings}
              setShowIncomingSettings={setShowIncomingSettings}
            />

            <div className="row mb-2 align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 text-end">
                <button
                  className="btn btn-default btnPaymentSetting"
                  onClick={() => setShowIncomingSettings(!showIncomingSettings)}
                >
                  <i className="bi bi-gear-fill" />
                </button>{" "}
                <button
                  className="btn btn-primary btnAddNewPaymentAccount"
                  onClick={() => setShowAddIncomingForm(!showAddIncomingForm)}
                >
                  <i className="bi bi-plus-lg" /> Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table
                id="financial_account_table"
                className="table table-striped dataTable no-footer"
                width="100%"
              >
                <thead>
                  <tr>
                    <th width="40%">Accounts</th>
                    <th width="40%">Type</th>
                    <th width="20%" />
                  </tr>
                </thead>
                <tbody>
                  {incomingList.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No incoming payment methods added yet. Want to{" "}
                        <a
                          style={{ color: "#125fad", cursor: "pointer" }}
                          onClick={() => setShowAddIncomingForm(true)}
                        >
                          click here & add one{" "}
                        </a>
                        now?
                      </td>
                    </tr>
                  )}
                  {incomingList.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data.account}</td>
                        <td>{data.type}</td>
                        <td className="text-end tableColAction d-none d-md-table-cell">
                          <a
                            href="#"
                            className="btn btn-delete"
                            title="Delete"
                            onClick={() => handleDelete(data.id)}
                          >
                            <i className="bi bi-trash-fill" />
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default IncomingMoney
