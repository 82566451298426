import * as React from "react"

import FinancialDetails from "../../../components/Settings/financial"
import PrivateRoute from "../../../components/privateRoute"
import ReduxWrapper from "../../../redux/reduxWrapper"
import queryString from "query-string"

const IndexPage = props => {
  return (
    <PrivateRoute
      component={FinancialDetails}
      location={props.location}
      params={queryString.parse(props.location.search)}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
