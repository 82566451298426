import * as React from "react"
import { useState } from "react"
import { Collapse } from "react-bootstrap"

const AddOutgoingMoney = ({ showAddOutgoingForm, setShowAddOutgoingForm }) => {
  const [paymentMethod, setPaymentMethod] = useState("CreditCard")

  return (
    <div className="row">
      <Collapse in={showAddOutgoingForm}>
        <div className="col-lg-6 mb-3 mx-auto addNewBillingPaymentPrefContainer">
          <div id="FrmAddNewPaymentMethod" className="form-horizontal">
            <div className="row ml-0 mr-0">
              <div className="col-lg-12 colHeading">
                <strong>
                  <i className="bi bi-plus-lg" /> Payment Method
                </strong>
              </div>
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 row align-items-center">
                      <div className="col-sm-12">
                        <div className="row align-items-center">
                          <div className="col-sm-12 mb-3">
                            <select
                              name="payment_method_outgoing_money"
                              id="payment_method_outgoing_money"
                              className="form-select"
                              onChange={e => setPaymentMethod(e.target.value)}
                            >
                              <option value="CreditCard">Credit Card</option>
                              <option value="ACH">ACH e-Check</option>
                            </select>
                          </div>
                          {paymentMethod === "CreditCard" && (
                            <div className="col-sm-12 paymentMethodOpt CreditCard_Container">
                              <div className="payment-method-card-wrapper" />
                              <div className="form-container mt-3">
                                <input
                                  className="form-control mb-3"
                                  placeholder="Card number"
                                  type="tel"
                                  name="cc_number"
                                  id="cc_number1"
                                />
                                <input
                                  className="form-control mb-3"
                                  placeholder="Full name"
                                  type="text"
                                  name="cc_name"
                                  id="cc_name1"
                                />
                                <div className="row">
                                  <div className="col-sm-6 mb-3">
                                    <input
                                      className="form-control"
                                      placeholder="MM/YY"
                                      type="tel"
                                      name="cc_expiration"
                                      id="cc_expiration1"
                                    />
                                  </div>
                                  <div className="col-sm-6 mb-3">
                                    <input
                                      className="form-control"
                                      placeholder="CVV"
                                      type="number"
                                      name="cc_CVV"
                                      id="cc_CVV1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {paymentMethod === "ACH" && (
                            <div className="col-sm-12 paymentMethodOpt ACH_Container">
                              <div className="row">
                                <div className="col-sm-6 mb-3">
                                  <input
                                    placeholder="Name of bank"
                                    type="text"
                                    name="ach_bank_name1"
                                    id="ach_bank_name1"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-sm-6 mb-3">
                                  <input
                                    placeholder="Name on account"
                                    type="text"
                                    name="ach_account_name1"
                                    id="ach_account_name1"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 mb-3">
                                  <input
                                    placeholder="Account number"
                                    type="text"
                                    name="ach_account_no1"
                                    id="ach_account_no1"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-sm-6 mb-3">
                                  <input
                                    placeholder="Routing number"
                                    type="text"
                                    name="ach_routing_no1"
                                    id="ach_routing_no1"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row align-items-center">
                        <label className="col-sm-4" />
                        <div className="col-sm-8">
                          <button
                            type="button"
                            className="btn btn-default btnCancelAddNewBillingPaymentPref"
                            onClick={() => setShowAddOutgoingForm(!showAddOutgoingForm)}
                          >
                            <i className="bi bi-x-lg" /> Cancel
                          </button>{" "}
                          <button type="button" className="btn btn-primary btnAddAccount">
                            <i className="bi bi-check2" /> Add Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default AddOutgoingMoney
