import * as React from "react"
import { Accordion, Modal } from "react-bootstrap"
import { useState } from "react"

import AddOutgoingMoney from "./addOutgoingMoney"

const OutGoingMoney = () => {
  const [showAddOutgoingForm, setShowAddOutgoingForm] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <i className="bi bi-box-arrow-right me-2" /> Outgoing Money / My Payment Methods
      </Accordion.Header>
      <Accordion.Body className={"p-3"}>
        <div className="row g-0">
          <div className="col-lg-12">
            <AddOutgoingMoney
              showAddOutgoingForm={showAddOutgoingForm}
              setShowAddOutgoingForm={setShowAddOutgoingForm}
            />
            <div className="row mb-2 align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 text-end">
                <button
                  className="btn btn-primary btnAddNewBillingPayment"
                  onClick={() => setShowAddOutgoingForm(!showAddOutgoingForm)}
                >
                  <i className="bi bi-plus-lg" /> Add New
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table
                id="tblUserFinanceBillingPayment"
                className="table table-striped dataTable no-footer"
                width="100%"
              >
                <thead>
                  <tr>
                    <th width="40%">Account Name</th>
                    <th width="40%">Used by / Linked Agreements...</th>
                    <th width="20%" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3} className="text-center">
                      No Outgoing Money / Payment Methods Added Yet. Want to{" "}
                      <a
                        style={{ color: "#125fad", cursor: "pointer" }}
                        onClick={() => setShowAddOutgoingForm(true)}
                      >
                        click here & add one{" "}
                      </a>
                      now?
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <h5 className="modal-title" id="userPaymentOptsModalLabel">
                Revoke Billing Method
              </h5>
            </Modal.Header>
            <Modal.Body>
              <p>
                The other party cannot see your credit card or payment full numbers, but has it stored for use
                per your permissions.
              </p>
              <p>
                This billing method cannot be revoked without revoking the associated agreement. Would you
                like to...
              </p>
              <div className="row row-cols-lg-auto g-2 align-items-center">
                <div className="col-12">
                  <input
                    type="radio"
                    name="editBillingMethods"
                    id="replaceBillingMethod"
                    value="replaceUpdateBillingMethod"
                    className="css-checkbox"
                  />
                  <label className="css-radio-label" htmlFor="replaceBillingMethod">
                    Replace / Update Billing Method
                  </label>
                </div>
                <div className="col-12 replaceUpdateBillingMethodOption d-none">
                  <select data-toggle="selectpicker" id="history_with">
                    <option value="visa" data-icon="fa-cc-visa">
                      ...4321
                    </option>
                    <option value="mastercard" data-icon="fa-cc-mastercard">
                      ...5432
                    </option>
                  </select>
                </div>
              </div>
              <div className="from-group-row">
                <input
                  type="radio"
                  name="editBillingMethods"
                  id="cancleAgreement"
                  value="cancelAgreement"
                  className="css-checkbox"
                  checked="checked"
                />
                <label className="css-radio-label radGroup2" htmlFor="cancleAgreement">
                  Cancel Agreement &amp; Revoke Billing Permissions
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-default" onClick={handleClose}>
                <i className="bi bi-x-lg" /> Abort Unchanged
              </button>
              <button type="button" className="btn btn-danger">
                <i className="bi bi-exclamation-circle-fill" /> Cancel Agreement & Revoke Billing Permissions
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default OutGoingMoney
